<template>
  <div class="page">
    <nav-bar PitchOn="留言"></nav-bar>
<!--    留言-->
    <div class="leave-info">
<!--      留言输入-->
      <div class="input-area">
        <div class="input-name">
          <input type="text" v-model="form.nickname" placeholder="请输入昵称">
        </div>
        <div class="input-content">
          <textarea v-model="form.content" placeholder="请输入留言内容"></textarea>
        </div>
        <div class="submit-btn">
          <div class="btn" @click="onLeaveSubmit">
            <span>提交</span>
          </div>
        </div>
      </div>
<!--      留言列表-->
      <div class="leave-list">
        <div class="leave-title">
          <span>留言内容</span>
        </div>
        <div class="word-list">
          <div class="item" v-for="(item,index) in leaveList" :key="index">
            <div class="user-date">
              <div class="user">
                <div class="pic">
                  <img :src="item.pic" alt="">
                </div>
                <div class="name">
                  <span>{{item.nickname}}</span>
                </div>
              </div>
              <div class="date">
                <span>{{parseTime(item.create_time,'{y}年{m}月{d}日')}}</span>
              </div>
            </div>
            <div class="content">
              <span class="tag"></span>
              <span>{{item.content}}</span>
            </div>
          </div>
        </div>
        <div class="pagination">
          <el-pagination background layout="prev, pager, next"
                         @current-change="changePage"
                         :page-size="20"
                         :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar/NavBar";
import {getCurrentInstance, onMounted, reactive, toRefs} from "vue";
import {LeaveList, LeavePut} from "../../api/api";
import {ElMessage} from "element-plus";
export default {
  name: "LeaveMessage",
  components: {NavBar},
  setup(){
    let { proxy } = getCurrentInstance();
    const data = reactive({
      parseTime: '',
      leaveList: [],
      form: {
        nickname: '',
        content: '',
      },
      // 页码
      page: 1,
      total: 0,
      /**
       * 获取数据
       */
      getData(){
        LeaveList({
          page: data.page,
        }).then(res =>{
          data.leaveList = res.data.result;
          data.total = res.data.total;
        })
      },
      /**
       * 页码改变
       * @param page
       */
      changePage(page){
        data.page = page;
        data.getData();
      },
      /**
       * 留言提交点击
       */
      onLeaveSubmit(){
        if(!data.form.nickname){
          ElMessage.error('请输入昵称');
          return;
        }
        if(!data.form.content){
          ElMessage.error('请输入内容');
          return;
        }
        LeavePut({
          nickname: data.form.nickname,
          content: data.form.content,
        }).then(res =>{
          ElMessage.success(res.msg)
          this.form.content = '';
          this.page = 1;
          this.getData();
        })
      }
    })
    onMounted(() => {
      data.parseTime = proxy.util.parseTime;
      data.getData();
    })
    return {
      ...toRefs(data),
    }
  }
}
</script>

<style scoped lang="scss">
  @import "LeaveMessage.scss";
</style>
